import React from 'react';
import HomeContact from "../home/homeContact";
import Footer from "../footer/footer";
import { Suspense, lazy } from 'react';

import SpicePowdersBg from '../../images/products/imgBg3.jpg'

const InnerProductBanner = lazy(() => import("./innerProductBaner"));

function SpicePowders() {
    return (
        <>
        <section id="innerProductBanner">
            <Suspense>
                <InnerProductBanner InnerProductBanerImg={SpicePowdersBg}></InnerProductBanner>
            </Suspense>
        </section>
        <section id="innerProductsDetails">
            <div className="container">
                <div className="innerProductsDetailsMain">
                    <h1>Spice Powders</h1>
                    <p>Explore the rich flavors of Zinnia Natural Ingredients' spice powders, meticulously crafted from premium ingredients like ginger, turmeric, black pepper, and more. Perfect for enhancing the taste and aroma of culinary creations, our spice powders guarantee quality and consistency, making every dish a flavorful masterpiece.</p>
                    <div className="innerProductsList">
                        <h3>Products</h3>
                        <ul>
                            <li><div className="dot"></div> Ginger</li>
                            <li><div className="dot"></div> Turmeric</li>
                            <li><div className="dot"></div> Black Pepper</li>
                            <li><div className="dot"></div> Cardamom</li>
                            <li><div className="dot"></div> Chillies</li>
                            <li><div className="dot"></div> Nutmeg</li>
                            <li><div className="dot"></div> Coriander</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <HomeContact></HomeContact>
        <Footer></Footer>
        </>
    );
}

export default SpicePowders;