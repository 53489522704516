import '../../style/style.css';
import { Suspense, lazy } from 'react';
import productImg1 from '../../images/products/img1.jpg'
import productImg2 from '../../images/products/img2.jpg'
import productImg3 from '../../images/products/img3.jpg'
import productImg4 from '../../images/products/img4.jpg'

const path = "";

const HomeProductsBox = lazy(() => import("./homeProductsBox"));

function HomeProducts() {
    return (
        <section id="products">
            <svg className='productsWave1' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#fff" fill-opacity="1" d="M0,160L60,165.3C120,171,240,181,360,165.3C480,149,600,107,720,117.3C840,128,960,192,1080,218.7C1200,245,1320,235,1380,229.3L1440,224L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path></svg>
            <div className="container">
                <div className="productsTitle">
                    <h2>Our Products</h2>
                </div>
                <div className="productsMain">
                    <Suspense>
                        <HomeProductsBox productLink={`${path}/EssentialOil`} productImg={productImg1} productTitle="Essential Oil"></HomeProductsBox>
                    </Suspense>
                    <Suspense>
                        <HomeProductsBox productLink={`${path}/NaturalFoodColors`} productImg={productImg2} productTitle="Natural Food Colors"></HomeProductsBox>
                    </Suspense>
                    <Suspense>
                        <HomeProductsBox productLink={`${path}/SpicePowders`} productImg={productImg3} productTitle="Spice Powders"></HomeProductsBox>
                    </Suspense>
                    <Suspense>
                        <HomeProductsBox productLink={`${path}/OleoresinExtracts`} productImg={productImg4} productTitle="Oleoresin Extracts"></HomeProductsBox>
                    </Suspense>
                </div>
            </div>
            <svg className='productsWave2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#fff" fill-opacity="1" d="M0,128L60,138.7C120,149,240,171,360,154.7C480,139,600,85,720,85.3C840,85,960,139,1080,138.7C1200,139,1320,85,1380,58.7L1440,32L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path></svg>
        </section>
    );
}

export default HomeProducts;