import '../../style/main.css';
import { Link } from 'react-router-dom';

import { UilFacebookF, UilInstagram, UilYoutube } from '@iconscout/react-unicons'

function NavStrip() {
    return (
        <div className='navStrip'>
            <div className="container">
                <div className="navStripMain">
                    <div className="navStripCol1">
                        <Link to="tel:+447776629866">+44 7776629866</Link>
                        <span>|</span>
                        <Link to="mailto:sales@zinniaingredients.com">sales@zinniaingredients.com</Link>
                    </div>
                    <div className="navStripCol2" style={{display: 'none'}}>
                        <Link>
                            <UilFacebookF></UilFacebookF>
                        </Link>
                        <Link>
                            <UilInstagram></UilInstagram>
                        </Link>
                        <Link>
                            <UilYoutube></UilYoutube>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NavStrip;