import React, { useState,useRef } from 'react';
import HomeContact from "../home/homeContact";
import Footer from "../footer/footer";
import { Link } from 'react-router-dom';
import { UilMobileAndroid, UilAt, UilLocationPoint } from '@iconscout/react-unicons';
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import qs from 'qs'; 
import { ToastContainer, toast } from 'react-toastify'; // Import ToastContainer and toast
import 'react-toastify/dist/ReactToastify.css'; // Import CSS for toast

function Contact() {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        message: '',
        recaptchaToken: ''
    });

    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [recaptchaToken, setRecaptchaToken] = useState(null);
    const recaptchaRef = useRef(); // Create a ref for the reCAPTCHA
    const validate = () => {
        const errors = {};
        if (!formData.name.trim()) {
            errors.name = 'Name is required';
        }
        if (!formData.phone.trim()) {
            errors.phone = 'Phone number is required';
        } else if (formData.phone.length < 5 || formData.phone.length > 15) {
            errors.phone = 'Phone number must be between 5 and 15 characters';
        }
        
        if (!formData.email.trim()) {
            errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            errors.email = 'Email address is invalid';
        }

        if (!recaptchaToken) {
            errors.recaptcha = 'Please complete the reCAPTCHA';
        }
        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validate();
        
        if (Object.keys(validationErrors).length === 0) {
            setIsSubmitting(true);
            setErrors({});
            setFormData({ name: '', phone: '', email: '', message: ''});
            setRecaptchaToken(null)
            recaptchaRef.current.reset(); // Reset the reCAPTCHA
            try {
                const response = await axios.post('https://drnaturalsclinic.com/ziniamailer/send-mail.php', 
                    qs.stringify({ 
                        name: formData.name,
                        phone: formData.phone,
                        email: formData.email,
                        message: formData.message,
                        recaptchaToken // Pass recaptcha token to backend
                    }), 
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded' 
                        }
                    }
                );
    
                console.log('Form submitted successfully:', response.data);
                
                if(response.data.status === 1) {
                    
                    setRecaptchaToken(null); 
                    toast.success("Message sent successfully!"); // Show success toast
                }
            } catch (error) {
                console.error('Error submitting form:', error);
                toast.error("Error sending message! Please try again."); // Show error toast
            } finally {
                setIsSubmitting(false);
            }
        } else {
            setErrors(validationErrors);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        if (errors[name]) {
            setErrors({ ...errors, [name]: null });
          }
    };

    const onRecaptchaChange = (token) => {
        setRecaptchaToken(token); 
    };

    return (
        <>
            <ToastContainer /> {/* Add ToastContainer for notifications */}
            <section id='contact'>
                <div className="contactHead">
                    <div className="container">
                        <div className="contactHeadMain">
                            <h1>Connect With Us</h1>
                        </div>
                    </div>
                </div>
                <div className="contactBody">
                    <div className="container">
                        <div className="contactBodyMain">
                            <div className="contactBodyDetails">
                                <p>Partner with Zinnia Natural Ingredients for superior quality and innovative flavors that set your products apart from the rest.</p>
                                <ul>
                                    <li>
                                        <div className="homeContactCol1">
                                            <UilMobileAndroid></UilMobileAndroid>
                                            <h3>Phone</h3>
                                        </div>
                                        <div className="homeContactCol2">
                                            <Link to="tel:+447776629866">+44 77766 29866</Link>
                                            <Link to="tel:+447442369246">+44 74423 69246</Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="homeContactCol1">
                                            <UilAt></UilAt>
                                            <h3>Email</h3>
                                        </div>
                                        <div className="homeContactCol2">
                                            <Link to="mailto:sales@zinniaingredients.com">sales@zinniaingredients.com</Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="homeContactCol1">
                                            <UilLocationPoint></UilLocationPoint>
                                            <h3>Address</h3>
                                        </div>
                                        <div className="homeContactCol2">
                                            <p>7 Bell Yard, London, <br />England, WC2A 2JR</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="contactBodyForm">
                                <h3>We’re Here To Help</h3>
                                <p className='disc'>We're here to help with all your inquiries and needs. Contact us for expert assistance and personalized support anytime.</p>
                                <form onSubmit={handleSubmit}>
                                    <div className="formGroup">
                                        <input 
                                            type="text" 
                                            name="name" 
                                            placeholder='Name' 
                                            value={formData.name} 
                                            onChange={handleInputChange} 
                                        />
                                        {errors.name && <span className="error">{errors.name}</span>}
                                    </div>
                                    <div className="formGroup">
                                        <input 
                                            type="number" 
                                            name="phone" 
                                            placeholder='Phone' 
                                            value={formData.phone} 
                                            onChange={handleInputChange} 
                                        />
                                        {errors.phone && <span className="error">{errors.phone}</span>}
                                    </div>
                                    <div className="formGroup">
                                        <input 
                                            type="email" 
                                            name="email" 
                                            placeholder='Email' 
                                            value={formData.email} 
                                            onChange={handleInputChange} 
                                        />
                                        {errors.email && <span className="error">{errors.email}</span>}
                                    </div>
                                    <div className="formGroup">
                                        <textarea 
                                            name="message" 
                                            placeholder='Message' 
                                            value={formData.message} 
                                            onChange={handleInputChange} 
                                        />
                                    </div>
                                    {/* reCAPTCHA */}
                                    <div className="formGroup">
                                        <ReCAPTCHA
                                            sitekey="6LfAfF0qAAAAAKL0cd0rM9DsLztg3UUM0-7Hnrd3" 
                                            onChange={onRecaptchaChange}
                                            ref={recaptchaRef}
                                        />
                                        {errors.recaptcha && <span className="error">{errors.recaptcha}</span>}
                                    </div>
                                    <div className="formBtnArea">
                                        <button className='primaryBtn' disabled={isSubmitting}>
                                            <svg viewBox="0 0 30 42">
                                                <path d="m 13.552735,2.0426703 q 1.5,3.7999997 10,14.9999997 a 12.8,12.8 0 1 1 -20.0000007,0 Q 12.052736,5.84267 13.552735,2.0426703 Z" id="path2" />
                                                <path fill="transparent" d="M 0,1.3127116e-4 V 38.582162 H 27.10547 V 1.3127116e-4 Z M 13.552735,2.0431 c 1,2.5333332 4.333334,7.5333335 10,15 a 12.8,12.8 0 1 1 -20.000001,0 c 5.6666682,-7.4666665 9.000002,-12.4666668 10.000001,-15 z" id="rect4487" />
                                            </svg>
                                                {isSubmitting ? (
                                                <span>Loading...</span> // Show loading text or spinner
                                            ) : (
                                                <span>Send Now</span>
                                            )}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <HomeContact></HomeContact>
            <Footer></Footer>
        </>
    );
}

export default Contact;
