import '../../style/style.css';
import { Suspense, lazy } from 'react';
import whyUsImg1 from '../../images/whyUs/img1.jpg';
import whyUsImg2 from '../../images/whyUs/img2.jpg';
import whyUsImg3 from '../../images/whyUs/img3.jpg';

const HomeWhyUsImg = lazy(() => import("./homeWhyUsImg"));

function HomeWhyUs() {
    return (
        <section id="whyUs">
            <div className="container">
                <div className="whyUsTitle">
                    <h2>Why Zinnia Products</h2>
                </div>
                <div className="whyUsMain">
                    <div className="whyUsBox">
                        <div className="whyUsBoxThumbnail">
                            <Suspense>
                                <HomeWhyUsImg whyUsImg={whyUsImg1}></HomeWhyUsImg>
                            </Suspense>
                        </div>
                        <div className="whyUsBoxDetails">
                            <h3>Our Process</h3>
                            <p>We collaborate with manufacturers, tailoring our services to meet unique needs. Our process ensures quality and availability by monitoring market conditions and providing expert guidance at every step.</p>
                        </div>
                    </div>
                    <div className="whyUsBox">
                        <div className="whyUsBoxThumbnail">
                            <Suspense>
                                <HomeWhyUsImg whyUsImg={whyUsImg2}></HomeWhyUsImg>
                            </Suspense>
                        </div>
                        <div className="whyUsBoxDetails">
                            <h3>Quality Products</h3>
                            <p>We supply premium essential oils, oleoresins, natural colors, and spice powders. Our products enhance food and beverage quality, ensuring consistent color, aroma, and taste for superior end products.</p>
                        </div>
                        </div>
                    <div className="whyUsBox">
                        <div className="whyUsBoxThumbnail">
                            <Suspense>
                                <HomeWhyUsImg whyUsImg={whyUsImg3}></HomeWhyUsImg>
                            </Suspense>
                        </div>
                        <div className="whyUsBoxDetails">
                            <h3>Our Experience</h3>
                            <p>With over 20 years of expertise, we excel at meeting demands. Our team provides personalized support, ensuring each order meets the highest standards and unique customer requirements.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HomeWhyUs;