import '../../style/style.css';
import { Link } from 'react-router-dom';
import PrimaryButton from '../button/button';

const path = "";

function HomeContact() {
    return (
        <section id="contactStrip">
            <div className="container">
                <div className="contactStripMain">
                    <h2>Get in Touch with ZINNIA</h2>
                    <p>We'd love to hear from you! Contact ZINNIA for product inquiries, order assistance, or to learn how we can enhance your business.</p>
                    <div className="contactStripDetails">
                        <PrimaryButton cls='secondaryBtn contactStripLink' lnk="tel:+447776629866" title="+44 77766 29866"></PrimaryButton>
                        <PrimaryButton cls='secondaryBtn contactStripLink' lnk="mailto:sales@zinniaingredients.com" title="sales@zinniaingredients.com"></PrimaryButton>
                        <PrimaryButton cls="primaryBtn contactBtn" lnk={`${path}/Contact`} title="Contact Now"></PrimaryButton>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HomeContact;