
import HomeContact from "../home/homeContact";
import Footer from "../footer/footer";
import { Suspense, lazy } from 'react';

import essentialOilBg from '../../images/products/imgBg1.jpg'
// import essProduct1 from '../../images/products/pepper.jpg'
// import essProduct2 from '../../images/products/Calamus.jpg'
// import essProduct3 from '../../images/products/Cardamom.jpg'

const InnerProductBanner = lazy(() => import("./innerProductBaner"));
// const InnerProductsList = lazy(() => import("./innerProductsList"));

function EssentialOil() {
    return (
        <>
        <section id="innerProductBanner">
            <Suspense>
                <InnerProductBanner InnerProductBanerImg={essentialOilBg}></InnerProductBanner>
            </Suspense>
        </section>
        <section id="innerProductsDetails">
            <div className="container">
                <div className="innerProductsDetailsMain">
                    <h1>Essential Oil</h1>
                    <p>Our essential oils are carefully sourced and scientifically backed, ensuring purity and potency. We offer a diverse range including black pepper, calamus, cardamom, and more. Each oil is crafted to enhance the flavor, aroma, and health benefits of your products. Our commitment to quality and consistency helps manufacturers deliver superior, naturally flavored food and beverage products.</p>
                    <div className="innerProductsList">
                        <h3>Products</h3>
                        <ul>
                            <li><div className="dot"></div> Black Pepper</li>
                            <li><div className="dot"></div> Calamus</li>
                            <li><div className="dot"></div> Cardamom</li>
                            <li><div className="dot"></div> Celery Seed</li>
                            <li><div className="dot"></div> Cinnamon Leaf</li>
                            <li><div className="dot"></div> Cinnamon Bark</li>
                            <li><div className="dot"></div> Clove Bud</li>
                            <li><div className="dot"></div> Clove Leaf</li>
                            <li><div className="dot"></div> Coriander Seed</li>
                            <li><div className="dot"></div> Cumin Seed</li>
                            <li><div className="dot"></div> Davana</li>
                            <li><div className="dot"></div> Galangal</li>
                            <li><div className="dot"></div> Ginger</li>
                            <li><div className="dot"></div> Nutmeg</li>
                            <li><div className="dot"></div> Mace</li>
                            <li><div className="dot"></div> Turmeric</li>
                            <li><div className="dot"></div> Curry leaf</li>
                            <li><div className="dot"></div> Ajowan</li>
                            <li><div className="dot"></div> Garlic</li>
                        </ul>
                        {/* <Suspense>
                            <InnerProductsList InnerProductListImg={essProduct1} InnerProductListTitle="Black Pepper" InnerProductListDesc="Our black pepper essential oil is renowned for its robust flavor and aromatic profile. Sourced from premium quality black pepper, this oil enhances the taste and aroma of various culinary applications. Ideal for food and beverage manufacturers, it ensures a consistent, high-quality ingredient that elevates the overall sensory experience of your products, making them truly exceptional."></InnerProductsList>
                            <InnerProductsList InnerProductListImg={essProduct2} InnerProductListTitle="Calamus" InnerProductListDesc="Calamus essential oil, derived from the roots of the calamus plant, offers a unique, warm, and spicy aroma. Known for its therapeutic properties, it is used in flavoring, perfumes, and medicinal products. Our high-quality calamus oil ensures purity and potency, providing manufacturers with a versatile ingredient to enhance the flavor and health benefits of their products."></InnerProductsList>
                            <InnerProductsList InnerProductListImg={essProduct3} InnerProductListTitle="Cardamom" InnerProductListDesc="Cardamom essential oil, extracted from the seeds of premium cardamom pods, offers a sweet, spicy, and aromatic profile. Widely used in food and beverage products, it imparts a distinct and delightful flavor. Our cardamom oil ensures superior quality and consistency, helping manufacturers create flavorful, high-quality products that stand out in the market."></InnerProductsList>
                        </Suspense> */}
                    </div>
                </div>
            </div>
        </section>
        <HomeContact></HomeContact>
        <Footer></Footer>
        </>
    );
}

export default EssentialOil;