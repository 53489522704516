import '../../style/style.css';
import { Link } from 'react-router-dom';
import esightLogo from '../../images/esight.png'
import footerLogo from "../../images/zinnia-logo.png"

import { UilFacebookF, UilInstagram, UilYoutube, UilHeart } from '@iconscout/react-unicons'

const path = "";

function Footer() {
    return (
        <footer>
            <div className="container">
                <div className="footerMain">
                    <div className="footerCol1">
                        <div className="footerLogo">
                            <img src={footerLogo} alt="" />
                            {/* <h2>Zinnia</h2> */}
                        </div>
                        {/* <div className="footerSocialLinksMain">
                            <span>Follow Us On</span>
                            <div className="footerSocialLinks">
                                <Link>
                                    <UilFacebookF></UilFacebookF>
                                </Link>
                                <Link>
                                    <UilInstagram></UilInstagram>
                                </Link>
                                <Link>
                                    <UilYoutube></UilYoutube>
                                </Link>
                            </div>
                        </div> */}
                    </div>
                    <div className="footerCol2">
                        <h3>Quick Links</h3>
                        <ul>
                            <li>
                                <Link to={`${path}/`}>Home</Link>
                            </li>
                            <li>
                                <Link to={`${path}/About`}>About</Link>
                            </li>
                            <li>
                                <Link to={`${path}/Contact`}>Contact</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="footerCol3">
                        <h3>Products</h3>
                        <ul>
                            <li>
                                <Link to={`${path}/EssentialOil`}>Essential Oil</Link>
                            </li>
                            <li>
                                <Link to={`${path}/NaturalFoodColors`}>Natural Food Colors</Link>
                            </li>
                            <li>
                                <Link to={`${path}/SpicePowders`}>Spice Powders</Link>
                            </li>
                            <li>
                                <Link to={`${path}/OleoresinExtracts`}>Oleoresin Extracts</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="footerCol4">
                        <h3>Connect Us</h3>
                        <div className="footerCol4Main">
                            <div className="footerCol4Box">
                                <span>Address</span>
                                <p>7 Bell Yard, London, <br />England, WC2A 2JR</p>
                            </div>
                            <div className="footerCol4Box">
                                <span>Phone</span>
                                <Link to="tel:+447776629866">+44 77766 29866</Link>
                                <Link to="tel:+447442369246">+44 74423 69246</Link>
                                <span>Email</span>
                                <Link to="mailto:sales@zinniaingredients.com">sales@zinniaingredients.com</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footerStrip">
                    <div className="footerStripMainCol1">
                        <p>Copyright © 2024 Zinnia Natural Ingredients Ltd. All Rights Reserved.</p>
                    </div>
                    <div class="footerStripMainCol2">
                        <div class="desined"><p>Designed with</p> <UilHeart></UilHeart> <Link to="https://esightsolutions.com/" target="_blank" rel="noopener noreferrer"><div class="footerStripLogo">
                            <img src={esightLogo} alt="" />
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;