
import Nav from './components/nav/nav';
import { Routes, Route } from 'react-router-dom';
import Home from './components/home/home';
import NavStrip from './components/nav/navStrip';
import About from './components/about/about';
import Products from './components/products/products';
import EssentialOil from './components/products/essentialOil';
import NaturalFoodColors from './components/products/naturalFoodColors';
import SpicePowders from './components/products/spicePowders';
import OleoresinExtracts from './components/products/oleoresinExtracts';
import Contact from './components/contact/contact';
import Sidemenu from './components/nav/sidemenu';

import ScrollToTop from './components/srollTop';

const path = "";

function App() {
  return (
    <>
        <div className="App">
            <Sidemenu></Sidemenu>
            <div className='shimmer'></div>
            <NavStrip></NavStrip>
            <Nav></Nav>
            <ScrollToTop />
            <Routes>
                <Route path={`${path}/`} element={<Home />} />
                <Route path={`${path}/About`} element={<About />} />
                <Route path={`${path}/Products`} element={<Products />} />
                <Route path={`${path}/EssentialOil`} element={<EssentialOil />} />
                <Route path={`${path}/NaturalFoodColors`} element={<NaturalFoodColors />} />
                <Route path={`${path}/SpicePowders`} element={<SpicePowders />} />
                <Route path={`${path}/OleoresinExtracts`} element={<OleoresinExtracts />} />
                <Route path={`${path}/Contact`} element={<Contact />} />
            </Routes>
        </div>
        </>
  );
}

export default App;
