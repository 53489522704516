import React from 'react';
import HomeContact from "../home/homeContact";
import Footer from "../footer/footer";
import { Suspense, lazy } from 'react';

import NaturalFoodColorsBg from '../../images/products/imgBg2.jpg'

const InnerProductBanner = lazy(() => import("./innerProductBaner"));

function NaturalFoodColors() {
    return (
        <>
        <section id="innerProductBanner">
            <Suspense>
                <InnerProductBanner InnerProductBanerImg={NaturalFoodColorsBg}></InnerProductBanner>
            </Suspense>
        </section>
        <section id="innerProductsDetails">
            <div className="container">
                <div className="innerProductsDetailsMain">
                    <h1>Natural Food Colors</h1>
                    <p>Zinnia Natural Ingredients offers a spectrum of vibrant natural food colors sourced from premium spices like paprika and turmeric. Our colors are free from synthetic additives, ensuring purity and safety. Enhance the visual appeal of your food and beverage products naturally with our range of high-quality, natural food coloring solutions.</p>
                </div>
            </div>
        </section>
        <HomeContact></HomeContact>
        <Footer></Footer>
        </>
    );
}

export default NaturalFoodColors;