import HomeBanner from "./homeBanner";
import HomeWhyUs from "./homeWhyUs";
import HomeProducts from "./homeProducts";
import HomeAbout from "./homeAbout";
import HomeContact from "./homeContact";
import Footer from "../footer/footer";

function Home() {
    return (
        <>
        <HomeBanner></HomeBanner>
        <HomeWhyUs></HomeWhyUs>
        <HomeProducts></HomeProducts>
        <HomeAbout></HomeAbout>
        <HomeContact></HomeContact>
        <Footer></Footer>
        </>
    );
}

export default Home;