import '../../style/style.css';
import aboutVideoImg from '../../images/aboutVideoBg.jpg'
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import spoonOliveImg from '../../images/spoonOlive.png'
import { UilMultiply } from '@iconscout/react-unicons'

import aboutVideo from '../../images/aboutVideo.mp4'

function AboutVideo() {

    function aboutVideoPlayBtn() {
        document.querySelector('.aboutVideoPopup').style.display = 'block';
        document.querySelector('.aboutVideoPopup').style.opacity = 1;
        document.querySelector('.shimmer').style.display = 'block';
        document.querySelector('.shimmer').style.opacity = 1;
        var video = document.getElementById('aboutVideo');
        video.currentTime = 0;
        video.play();
    }

    function closeAboutVideoPopup(){
        document.querySelector('.aboutVideoPopup').style.display = 'none';
        document.querySelector('.aboutVideoPopup').style.opacity = 0;
        document.querySelector('.shimmer').style.display = 'none';
        document.querySelector('.shimmer').style.opacity = 0;
        document.getElementById('aboutVideo').pause();
    }

    return (
        <>
        <div className="aboutVideoPopup">
            <div className="closeAboutVideoPopup" onClick={closeAboutVideoPopup}>
                <UilMultiply></UilMultiply>
            </div>
            <video id='aboutVideo' controls playsinline="" data-wf-ignore="true" data-object-fit="cover" className="previewPopupVideo">
                <source src={aboutVideo} type="video/mp4"></source>
            </video>
        </div>
        <section id="aboutVideo">
            <div className="aboutVideoBox">
                <img src={aboutVideoImg} alt="" />
                <div className="aboutVideoBoxPlayBtn" onClick={aboutVideoPlayBtn}>
                    <PlayCircleIcon></PlayCircleIcon>
                </div>
            </div>
            <div className="aboutVideoDetail">
                <div className="container">
                    <div className="aboutVideoDetailMian">
                        <div className="aboutVideoDetailBox1">
                            <h2>Ensuring Superior Standards in Essential Oils, Oleoresins, and Natural Colors for Your Peace of Mind</h2>
                        </div>
                        <div className="aboutVideoDetailBox2">
                            <img src={spoonOliveImg} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}

export default AboutVideo;