import '../../style/style.css';
import supportImg from '../../images/support.jpg'

function AboutCustomerService() {
    return (
        <section id="aboutCustomerService">
            <div className="container">
                <div className="aboutCustomerServiceMain">
                    <div className="aboutCustomerServiceDetails">
                        <h2>Customer Service</h2>
                        <p>We, Zinnia Natural Ingredients Ltd. prioritizes exceptional customer service in all aspects of our operations. We strive to understand your unique needs and tailor our services to ensure your satisfaction. Our dedicated team offers personalized support and expert guidance at every step, from order placement to delivery. We prioritize clear communication, prompt responses, and reliable solutions, ensuring a seamless experience for our clients.</p>
                    </div>
                    <div className="aboutCustomerServiceThumbnail">
                        <img src={supportImg} alt="" />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AboutCustomerService;