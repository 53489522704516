import '../../style/style.css';

function AboutBanner() {
    return (
        <section id="aboutBanner">
            <div className="container">
                <div className="aboutBannerMain">
                    <h1>About Zinnia</h1>
                    <p>Transform your creations with Zinnia Natural Ingredients where flavor meets innovation, and quality defines every drop.</p>
                </div>
            </div>
        </section>
    );
}

export default AboutBanner;