import '../../style/style.css';
import { Suspense, lazy } from 'react';
import PrimaryButton from '../button/button';
import aboutImg from '../../images/about.jpg'

const path = "";

const HomeAboutThumbnail = lazy(() => import("./homeAboutThumbnail"));

function HomeAbout() {
    return (
        <section id="about">
            <div className="container">
                <div className="aboutMain">
                    <Suspense>
                        <HomeAboutThumbnail aboutImg={aboutImg}></HomeAboutThumbnail>
                    </Suspense>
                    <div className="aboutDetails">
                        <h2>About Zinnia Natural’s</h2>
                        <p>Zinnia Natural Ingredients Ltd. specializes in supplying high-quality food additives, including essential oils, oleoresins, natural colors, and spice powders, that enhance the color, smell, and taste of food and beverage products. With over 20 years of expertise, we collaborate closely with manufacturers to meet unique needs, ensuring top-notch quality and availability. Our commitment to integrity, excellence, and customer-centricity helps businesses thrive confidently.</p>
                        <PrimaryButton cls="primaryBtn aboutBtn" lnk={`${path}/About`} title="View More"></PrimaryButton>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HomeAbout;