import { UilMultiply } from '@iconscout/react-unicons'
import { NavLink } from 'react-router-dom';

const path = "";

function Sidemenu() {

    const closeSideMenuBtn = () => {
        const sideMenu = document.querySelector('.sidemenu');
        if (sideMenu) {
            sideMenu.style.right = '-120%';
            sideMenu.style.transition = '.3s';
        }
        const shimmer = document.querySelector('.shimmer');
        if (shimmer) {
            shimmer.style.display = 'none'; // Ensure the element is displayed
            // setTimeout(() => {
            //     shimmer.classList.add('visible');
            // }, 10); // Slight delay to trigger CSS transition
        }
    }
    return (
        <div className="sidemenu">
            <div className="closeSidemenu" onClick={closeSideMenuBtn}>
                <UilMultiply></UilMultiply>
            </div>
            <div className="sidemenuLink">
                <ul>
                    <li>
                        <NavLink exact="true" to={`${path}/`} end className={({ isActive }) => (isActive ? 'sidemenuLinkActive' : '')} onClick={closeSideMenuBtn}>Home</NavLink>
                    </li>
                    <li>
                        <NavLink to={`${path}/About`} className={({ isActive }) => (isActive ? 'sidemenuLinkActive' : '')} onClick={closeSideMenuBtn}>About</NavLink>
                    </li>
                    <li>
                        <NavLink to={`${path}/EssentialOil`} className={({ isActive }) => (isActive ? 'sidemenuLinkActive' : '')} onClick={closeSideMenuBtn}>Essential Oil</NavLink>
                    </li>
                    <li>
                        <NavLink to={`${path}/NaturalFoodColors`} className={({ isActive }) => (isActive ? 'sidemenuLinkActive' : '')} onClick={closeSideMenuBtn}>Natural Food Colors</NavLink>
                    </li>
                    <li>    
                        <NavLink to={`${path}/SpicePowders`} className={({ isActive }) => (isActive ? 'sidemenuLinkActive' : '')} onClick={closeSideMenuBtn}>Spice Powders</NavLink>
                    </li>
                    <li>
                        <NavLink to={`${path}/OleoresinExtracts`} className={({ isActive }) => (isActive ? 'sidemenuLinkActive' : '')} onClick={closeSideMenuBtn}>Oleoresin Extracts</NavLink>
                    </li>
                    <li>
                        <NavLink exact="true" to={`${path}/Contact`} className={({ isActive }) => (isActive ? 'sidemenuLinkActive' : '')} onClick={closeSideMenuBtn}>Contact</NavLink>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Sidemenu;