import React from 'react';
import HomeContact from "../home/homeContact";
import Footer from "../footer/footer";
import { Suspense, lazy } from 'react';

import OleoresinExtractsBg from '../../images/products/imgBg4.jpg'

const InnerProductBanner = lazy(() => import("./innerProductBaner"));

function OleoresinExtracts() {
    return (
        <>
        <section id="innerProductBanner">
            <Suspense>
                <InnerProductBanner InnerProductBanerImg={OleoresinExtractsBg}></InnerProductBanner>
            </Suspense>
        </section>
        <section id="innerProductsDetails">
            <div className="container">
                <div className="innerProductsDetailsMain">
                    <h1>Oleoresin Extracts</h1>
                    <p>Experience the robust essence of Zinnia Natural Ingredients' oleoresin extracts, derived from premium spices like black pepper, ginger, and paprika. Our extracts capture the authentic flavor and natural color of these spices, perfect for elevating the taste and visual allure of food and beverage products. Ensure quality and purity in every application.</p><div className="innerProductsList">
                        <h3>Products</h3>
                        <ul>
                            <li><div className="dot"></div> Black Pepper</li>
                            <li><div className="dot"></div> Black Pepper Decolorised</li>
                            <li><div className="dot"></div> Ginger</li>
                            <li><div className="dot"></div> Nutmeg</li>
                            <li><div className="dot"></div> Mace</li>
                            <li><div className="dot"></div> Cardamom</li>
                            <li><div className="dot"></div> Celery Seed</li>
                            <li><div className="dot"></div> Capsicum</li>
                            <li><div className="dot"></div> Paprika</li>
                            <li><div className="dot"></div> Cumin</li>
                            <li><div className="dot"></div> Cinnamon</li>
                            <li><div className="dot"></div> Turmeric</li>
                            <li><div className="dot"></div> Curcumin powder</li>
                            <li><div className="dot"></div> Coriander</li>
                            <li><div className="dot"></div> Onion</li>
                            <li><div className="dot"></div> Vanilla Extract</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <HomeContact></HomeContact>
        <Footer></Footer>
        </>
    );
}

export default OleoresinExtracts;