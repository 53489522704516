import '../../style/main.css';
import React, { startTransition, useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import PrimaryButton from '../button/button';
import navLogo from "../../images/zinnia-logo.png"

const path = "";

function Nav() {
    const location = useLocation();

    const isActiveProducts = () => {
        return location.pathname.includes(`${path}/Products`) || location.pathname.includes(`${path}/EssentialOil`) || location.pathname.includes(`${path}/NaturalFoodColors`) || location.pathname.includes(`${path}/SpicePowders`) || location.pathname.includes(`${path}/OleoresinExtracts`);
    };

    const navBarClik = () => {
        const sideMenu = document.querySelector('.sidemenu');
        if (sideMenu) {
            sideMenu.style.right = '0';
            sideMenu.style.transition = '.3s';
        }
        const shimmer = document.querySelector('.shimmer');
        if (shimmer) {
            shimmer.style.display = 'block'; // Ensure the element is displayed
            setTimeout(() => {
                shimmer.classList.add('visible');
            }, 10); // Slight delay to trigger CSS transition
        }
    }

    // nav scroll
    const [newScrollPosition, setNewScrollPosition] = useState(0);
    const [lastScrollPosition, setLastScrollPosition] = useState(0);
    const [navClass, setNavClass] = useState('');

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPosition = window.scrollY;

            startTransition(() => {
                if (newScrollPosition > currentScrollPosition && currentScrollPosition) {
                    setNavClass('slideDown');
                } else if (newScrollPosition < currentScrollPosition) {
                    setNavClass('slideUp');
                }

                setNewScrollPosition(currentScrollPosition);
                setLastScrollPosition(currentScrollPosition);
            });
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [newScrollPosition]);

    return (
        <>
            <div className="shimmer"></div>
            <nav className={navClass}>
                <div className="container">
                    <div className="navMain">
                        <NavLink to={`${path}/`} className="navLogo">
                            <img src={navLogo} alt="Logo" />
                            {/* <h2>Zinnia</h2> */}
                        </NavLink>
                        <div className="navLink">
                            <ul>
                                <li>
                                    <NavLink exact="true" to={`${path}/`} end className={({ isActive }) => (isActive ? 'navLinkActive' : '')}>Home</NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${path}/About`} className={({ isActive }) => (isActive ? 'navLinkActive' : '')}>About</NavLink>
                                </li>
                                <li className='navDropDown'>
                                    <div className={isActiveProducts() ? 'navLinkActive':''}>Products</div>
                                    <ul className="navDropDownPopup">
                                        <li>
                                            <NavLink to={`${path}/EssentialOil`} className={({ isActive }) => (isActive ? 'navDropDownLinkActive' : '')}>Essential Oil</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={`${path}/NaturalFoodColors`} className={({ isActive }) => (isActive ? 'navDropDownLinkActive' : '')}>Natural Food Colors</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={`${path}/SpicePowders`} className={({ isActive }) => (isActive ? 'navDropDownLinkActive' : '')}>Spice Powders</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={`${path}/OleoresinExtracts`} className={({ isActive }) => (isActive ? 'navDropDownLinkActive' : '')}>Oleoresin Extracts</NavLink>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div className="navContact">
                            <PrimaryButton cls="primaryBtn navContactBtn" lnk={`${path}/Contact`} title="Contact Now"></PrimaryButton>
                        </div>
                        <div className="navBar">
                            <div onClick={navBarClik} className="navBarBox">
                                <span></span><span></span><span></span>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default Nav;
