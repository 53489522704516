import '../../style/style.css';
import { Suspense, lazy } from 'react';
import servVideo1 from '../../images/banner.mp4'
import placeholdeImg1 from '../../images/bannerPlceholder.jpg'
import PrimaryButton from '../button/button';

const path = "";

const HomeBannerVideo = lazy(() => import("./homeBannerVideo"));

function HomeBanner() {
    return (
        <header id="banner">
            <Suspense>
                <HomeBannerVideo servVideo={servVideo1} placeholdeImg={placeholdeImg1} />
            </Suspense>
            <div className="bannerTitle">
                <h1>Spicing Up Your Life With Colorful Flavors</h1>
            </div>
            <div className="bannerDetails">
                <div className="container">
                    <div className="bannerDetailsMain">
                        <p>Discover the essence of nature with ZINNIA. As a leading provider of high-quality essential oils, oleoresins, and natural colors, we are dedicated to delivering purity and excellence in every product. Whether you're in the food, beverage, cosmetics, or pharmaceutical industry, trust ZINNIA to enhance your creations with our meticulously sourced and expertly crafted natural solutions. Experience the vibrant and authentic touch of nature with ZINNIA.</p>
                        <PrimaryButton cls="primaryBtn bannerBtn" lnk={`${path}/Contact`} title="Contact Now"></PrimaryButton>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default HomeBanner;