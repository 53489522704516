
import HomeContact from "../home/homeContact";
import Footer from "../footer/footer";

function Products() {
    return (
        <>
        <HomeContact></HomeContact>
        <Footer></Footer>
        </>
    );
}

export default Products;