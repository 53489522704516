import AboutBanner from "./aboutBanner";
import AboutDetails from "./aboutDetails";
import AboutVideo from "./aboutVideo";
import HomeWhyUs from "../home/homeWhyUs";
import AboutCustomerService from "./aboutCustomerService";
import HomeContact from "../home/homeContact";
import Footer from "../footer/footer";

function About() {
    return (
        <>
        <AboutBanner></AboutBanner>
        <AboutDetails></AboutDetails>
        <AboutVideo></AboutVideo>
        {/* <HomeWhyUs></HomeWhyUs> */}
        <AboutCustomerService></AboutCustomerService>
        <HomeContact></HomeContact>
        <Footer></Footer>
        </>
    );
}

export default About;