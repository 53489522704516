import '../../style/style.css';
import aboutImg1 from '../../images/about2.jpg'

function AboutDetails() {
    return (
        <section id="innerAboutDetails">
            <div className="container">
                <div className="innerAboutDetailsMain">
                    <div className="innerAboutDetailsThumbnail">
                        <img src={aboutImg1} alt="" />
                    </div>
                    <div className="innerAboutDetailsDetails">
                        <h2>Excellence in Essential Oils and Natural Colors</h2>
                        <p>At Zinnia Natural Ingredients Ltd., we pride ourselves on our excellence in providing premium essential oils and natural colors. Our essential oils, including black pepper, calamus, cardamom, and more, are meticulously sourced and scientifically backed, ensuring unparalleled purity and potency. Our natural colors, derived from spices like paprika and turmeric, bring vibrant, natural hues to food and beverage products.</p>
                        <p>With over 20 years of expertise, we collaborate closely with manufacturers, tailoring our offerings to meet unique needs. Our commitment to quality and innovation guarantees that your products will consistently achieve superior flavor, aroma, and visual appeal.</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AboutDetails;